import E from 'wangeditor'
import { bucket, picDns } from '@/components/uploadFile/config'
import { getUUID } from '@/utils/formatData'
import axios from '@/utils/ajax'
import { apis } from '@/api'

interface Config {
  menus?: string[];
  zIndex?: number;
  uploadImgAccept?: string[];
  uploadImgMaxSize?: number;
  focus?: boolean;
  placeholder?: string;
  showLinkImg?: boolean;
  [propName: string]: any;
}

// 富文本上传图片
function customUploadImg (resultFiles: string[], insertImgFn: Function) {
  axios.get<string>(apis.common.uploadToken, { bucket }).then(token => {
    resultFiles.forEach((item: string) => {
      const data = new FormData()
      const date = new Date()
      const key = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${getUUID()}`
      data.append('file', item)
      data.append('token', token)
      data.append('key', key)
      axios.post<{ key: string }>(apis.common.uploadQiniup, data).then(res => {
        // 上传图片，返回结果，将图片插入到编辑器中
        insertImgFn(picDns + res.key)
      })
    })
  })
}

// 去除对象空元素, ele: element id; config: wangEdit config
export const initWangEdit = (ele: string, config: Config) => {
  const editor = new E(ele)
  const menus = ['head', 'bold', 'fontSize', 'italic', 'indent', 'lineHeight', 'foreColor', 'justify', 'image', 'splitLine', 'undo', 'redo']
  const uploadImgMaxSize = 10 * 1024 * 1024 // 10M
  const uploadImgAccept = ['jpg', 'jpeg', 'png']
  editor.config.menus = menus
  editor.config.uploadImgMaxSize = uploadImgMaxSize
  editor.config.uploadImgAccept = uploadImgAccept
  editor.config.focus = false // 取消自动聚焦
  editor.config.placeholder = '请输入内容'
  editor.config.showLinkImg = false // 隐藏网络图片上传
  editor.config.zIndex = 1
  editor.config.pasteFilterStyle = false
  editor.config = { ...editor.config, ...config }
  // 自定义上传
  editor.config.customUploadImg = (
    resultFiles: string[],
    insertImgFn: Function
  ) => {
    customUploadImg(resultFiles, insertImgFn)
  }
  return editor
}
